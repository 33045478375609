/* eslint-disable vue/no-parsing-error */
<template>
  <div class="container">
    <div class="row activity">
      <h4 class="text-center">Меҳнат соҳасидаги мурожаат ва низоларни ўрганиш
        ҳамда ҳал қилишга кўмаклашиш маркази тўғрисидаги<br>
        НИЗОМ </h4>

      <h6>
        I-боб. Умумий қоидалар
      </h6>
      1. Мазкур низом Мазкур Низом Ўзбекистон Республикаси Вазирлар Маҳкамасининг “Касбий билим ва малакаларни
      ривожлантириш тизимини янада такомиллаштириш бўйича қўшимча чора-тадбирлар тўғрисида” 2021 йил 30
      сентябрдаги 616-сонли қарорига мувофиқ Меҳнат соҳасидаги мурожаат ва низоларни ўрганиш маркази (кейинги
      ўринларда “Меҳнат-М маркази” деб юритилади) мақоми, асосий вазифалари, функциялари, ҳуқуқлари,
      мажбуриятлари ва жавобгарлигини, шунингдек, фаолиятини ташкил этиш тартибини белгилайди. <br>
      2. Мазкур Низомда қуйидаги тушунчалар қўлланилади:
      медиация - келиб чиққан низони тарафлар ўзаро мақбул қарорга эришиши учун уларнинг ихтиёрий розилиги
      асосида медиатор кўмагида ҳал қилиш усули;
      медиатор- медиацияни амалга ошириш учун тарафлар томонидан жалб этиладиган шахс;
      расмий сўровнома - мурожаат муаллифининг иш жойи ҳақидаги маълумотлар аниқлаштириш, иш берувчи билан
      боғланиш ҳамда иш берувчи томонидан тақдим этилиши зарур бўлган ҳужжатлар электрон тарзда ёки бевосита
      марказга олиб келиниши;
      медиатив келишув - медиацияни қўллаш натижасида медиация тарафлари томонидан эришилган келишув.
      Ижтимоий-меҳнат масалалари бўйича уч томонлама комиссия - Вазирлар Маҳкамасининг «Ижтимоий-меҳнат
      масалалари бўйича уч томонлама комиссиялар тўғрисида» 2019 йил 3 июлдаги 553-сон қарорига мувофиқ ташкил
      этилган Ўзбекистон Республикаси Ҳукумати, Республика касаба уюшмалари бирлашмалари ҳамда Иш берувчилар
      республика бирлашмаларидан иборат таркибдаги комиссия.
      Ҳудудий ижтимоий-меҳнат масалалари бўйича уч томонлама комиссия – “Ижтимоий-меҳнат масалалари бўйича уч
      томонлама комиссиялар тўғрисида низом” асосида ҳар бир ҳудудда ташкил этиладиган комиссия. <br>
      3. Меҳнат-М маркази ўз фаолиятида Ўзбекистон Республикаси Конституциясига ва қонунларига, Ўзбекистон
      Республикаси Олий Мажлиси палаталари қарорларига, Ўзбекистон Республикаси Президенти фармонлари,
      қарорлари ва фармойишларига, Ўзбекистон Республикаси Вазирлар Маҳкамаси қарорлари ва фармойишларига,
      шунингдек, Ўзбекистон Республикаси Бандлик ва меҳнат муносабатлари вазирлигининг буйруқлари ҳамда ушбу
      Низомга ва бошқа қонун ҳужжатларига амал қилади. <br>
      4. Меҳнат-М маркази юридик шахс мақомига эга бўлмаган Тошкент шаҳар Бандлик бош бошқармасидаги Давлат
      меҳнат инспекцияси тизимидаги таркибий бўлинма ҳисобланади. <br>
      5. Меҳнат-М маркази ўз фаолиятини ошкоралик, қонун устуворлиги, ўзаро ҳурмат, тенг ҳуқуқлилик
      тамойиллари асосида юритади. <br>
      6. Меҳнат-М маркази ўз фаолиятини давлат бошқаруви органлари ва хўжалик бирлашмалари, маҳаллий давлат
      ҳокимияти органлари ҳамда бошқа ташкилотлар билан ҳамкорликда амалга оширади. <br>
      7. Меҳнат-М марказининг расмий номи:<br>
      &nbsp;&nbsp;а) давлат тилида:
      тўлиқ номи – Тошкент шаҳар Бандлик бош бошқармасидаги Давлат меҳнат инспекцияси тизимидаги “Меҳнат
      соҳасидаги мурожаат ва низоларни ўрганиш ҳамда ҳал қилишга кўмаклашиш маркази”, қисқартирилгани-
      “Меҳнат-М маркази”; <br>&nbsp;
      б) рус тилида:
      тўлиқ номи – “Центр по изучению и содействию в разрешении обращений и споров в сфере труда”
      Государственной трудовой инспекции Главного управления занятости города Ташкента, қисқартирилгани –
      “Центр Мехнат-М”;<br>&nbsp;
      в) инглиз тилида:
      Centre of the State Labour Inspection of the Tashkent City Main Department of Employment for the Study
      and Promotion of Citizens' Appeals and Dispute Resolution in the Sphere of Labour қисқартирилгани -
      Mehnat-M Centre.<br>
      8. Меҳнат-М марказининг жойлашув манзили:
      Тошкент шаҳар, Миробод тумани, Шаҳрисабз кўчаси, 34-уй манзилга жойлаштириш.<br>
      9. Меҳнат-М маркази фаолиятининг асосий мақсади иш берувчилар ва ходимларнинг меҳнат ҳуқуқларини ҳимоя
      қилиш ҳамда меҳнатини муҳофаза қилишни кучайтириш, иш берувчилар билан ходимлар ўртасидаги мувозанатли
      меҳнат муносабатларини янада кўллаб-қувватлаш, шунингдек, иш берувчилар ва ходимлар ўртасидаги келиб
      чиққан меҳнат низоларини ўзаро келишув (медиация) усули билан ҳал қилишга кўмаклашиш, мурожаатларни
      тезкорлик билан кўриб чиқилишини самарадорлигини оширишдан иборатдир.
      Меҳнат-М маркази томонидан жисмоний ва юридик шахсларга кўрсатиладиган барча хизматлар беғараз (бепул)
      ҳисобланади. <br>

      <h6> II-боб. Меҳнат-М марказнинг асосий вазифалари ва функциялари</h6>
      10. Меҳнат-М марказнинг асосий вазифалари ҳисобланади:
      жисмоний ва юридик шахсларининг меҳнат муносабатлари соҳасидаги мурожаатларини тезкор кўриб чиқиш;
      меҳнат муносабатлари ва меҳнатни муҳофаза қилиш бўйича ходимлар ва иш берувчиларга методик ёрдам бериш
      ва маслаҳат хизматларини кўрсатиш, фуқароларнинг меҳнат ҳуқуқларини ҳимоя қилишда кўмаклашиш;
      иш берувчилар ва ходимлар ўртасидаги келиб чиққан меҳнат низоларини ўзаро келишув (медиация) усули билан
      ҳал қилишга кўмаклашиш.
      Меҳнат-М марказ доимий асосда Вазирлар Маҳкамасининг «Ижтимоий-меҳнат масалалари бўйича уч томонлама
      комиссиялар тўғрисида» 2019 йил 3 июлдаги 553-сон қарорига мувофиқ ташкил этилган Ижтимоий-меҳнат
      масалалари бўйича ҳудудий уч томонлама комиссиясига жамоа музокараларини ўтказишда кўмаклашади.
      меҳнат низоларини ҳал қилишга кўмаклашиш борасида келиб чиққан келишмовчиликларни музокаралар асосида
      ҳал қилиш имкони бўлмаганда, низони ҳудудий уч томонларма комиссияда жамоа музокаралари шаклида кўриб
      чиқилишига кўмаклашиш;
      иш берувчилар ва ходимлар ўртасидаги келиб чиққан меҳнат низоларини ўзаро келишув (медиация) усули билан
      ҳал қилиш имкони бўлмаган ҳолларда мурожаатни ўрнатилган тартибда кўриб чиқиш учун Давлат меҳнат
      инспекторига йўналтириш;
      суд тартибида кўриб чиқиладиган меҳнат низоларини сайёр суд тарзида Меҳнат-М маркази биносида ташкил
      этиш чораларини кўриш.
      Меҳнат-М маркази қонунчиликда белгиланган бошқа вазифаларни амалга ошириши мумкин . <br>
      11. Меҳнат-М марказнинг асосий вазифаларини бажариш учун қуйидаги функцияларни амалга оширади:
      1)жисмоний ва юридик шахсларининг меҳнат муносабатлари соҳасидаги мурожаатларини «бир дарча» тамойили
      асосида тезкор кўриб чиқиш:
      мурожаатларни кўриб чиқишда мурожаатни кўриб чиқиш учун зарур бўлган ҳужжатлар тўлиқ тўплаш, қонун
      ҳужжатларида белгиланган тартибда мониторинглар ва ўрганишлар ўтказиш;
      мурожаатларини ўрганишда мурожаатчи, иш берувчи, унинг мансабдор шахслари билан тўғридан-тўғри мулоқот
      қилиш имкони бўлмаса, уларнинг иштирокисиз қисқа муддатларда кўриб чиқилишини таъминлаш; <br>
      2)иш берувчилар ва ходимлар ўртасидаги келиб чиққан меҳнат низоларини келишув (медиация) усули билан ҳал
      қилишда кўмаклашиш;
      меҳнат низолари вужудга келганда мурожаат қилган ходим ёки иш берувчиларга низоларни келишув (медиация)
      усули билан ҳал қилиш тартиби ва унинг ҳуқуқлари тўғрисида тушунтиришлар бериш;
      меҳнат низоларини келишув (медиация) усулида ҳал этилишида кўмаклашиш мақсадида ташкилий ишларни амалга
      ошириш;
      меҳнат низолари медиация усулида келишувга эришилмаганда, ушбу низоларни ҳудудий ижтимоий-меҳнат
      масалалари бўйича уч томонлама комиссиянинг жамоа музокаралари тартибида ҳал қилишга кўмаклашиш; <br>
      3)меҳнат муносабатлари ва меҳнатни муҳофаза қилиш бўйича ходимлар ва иш берувчиларга методик ёрдам бериш
      ва маслаҳат хизматларини кўрсатиш, фуқароларнинг меҳнат ҳуқуқларини ҳимоя қилиш;
      мурожаатларни ўрганиш давомида қонун бузилиш ҳолатлари аниқланганда, ушбу қонунбузилиш ҳолатларини
      бартараф этиш юзасидан меҳнат муносабатлари ва меҳнатни муҳофаза қилиш бўйича методик амалий ёрдамлар
      кўрсатиш;
      тегишли маслаҳатлар олишга оид мурожаатлар бўйича дарҳол малакали маслаҳат ҳамда тушунтиришлар бериш;
      меҳнат ҳуқуқлари бузилган фуқароларни ҳуқуқларини қонун ҳужжатларида белгиланган ваколатлар доирасида
      ҳимоя қилиш;
      қонун бузилишларга йўл қўйган айбдор шахсларга нисбатан тегишли таъсир чораларини кўрилишида Давлат
      меҳнат инспекциясига кўмаклашиш. <br>

      <h6>
        III-боб. Меҳнат-М марказининг иш фаолиятини ташкил этиш
      </h6>
      12. Меҳнат-М маркази фаолияти Ўзбекистон Республикаси Бандликка кўмаклашиш давлат жамғармаси ҳамда
      қонунчиликда тақиқланмаган бошқа манбалардан шакллантирилади ҳамда бевосита Бандлик ва меҳнат
      муносабатлари вазирлигининг Давлат меҳнат инспекцияси назорати остида фаолият кўрсатади.<br>
      13. Меҳнат-М марказига Тошкент шаҳар Бандлик бош бошқармасининг Давлат меҳнат инспекция бошлиғи – марказ
      директори раҳбарлик қилади. <br>
      14. Меҳнат-М марказда жорий этилган лавозимларга олий маълумотга эга бўлган шахслар тайинланади.
      Меҳнат-М марказда жорий этилган лавозимларга ходимларни ишга қабул қилиш Бандлик ва меҳнат муносабатлари
      вазирлиги билан олдиндан келишган ҳолда амалга оширилади. <br>
      15. Меҳнат-М маркази ходимларининг штат бирликлари Бандликка кўмаклашиш давлат жамғармаси маблағлари
      ҳисобидан жорий этилади. <br>
      16. Меҳнат-М маркази ходимлари учун иш ҳақи миқдори Тошкент шаҳар Бандлик бош бошқармаси тегишли
      мутахассисларининг иш ҳақи ва рағбатлантирувчи тўловлари миқдорига тенглаштирилади.
      17. Мурожаатлар жисмоний ва юридик шахсларнинг мурожаатлари тўғрисидаги қонун ҳужжатларида белгиланган
      тартибда ва муддатда кўриб чиқилади, бунда, фуқароларнинг бузилган ҳуқуқларини тиклаш мақсадида
      мурожаатларни текзорлик билан 1-5 иш кунида кўриб чиқиш амалиёти қўлланилади. <br>
      18. Меҳнат-М марказда мурожаатларни қабул қилиш - автоматик навбат (навбат чипталари) асосида амалга
      оширилади.
      Мурожаатларни қабул қилиш, рўйхатга олиш ва ижрога йўналтириш, шунингдек унинг ижросини назорат қилиш
      Меҳнат-М марказнинг аризаларни қабул қилиш бўлими томонидан (кейинги ўринларда – масъул ходимлар) амалга
      оширилади. <br>
      19. Барча келиб тушган мурожаатлар ягона электрон тизим (“Mehnat M” электрон портали)да рўйхатга
      олинади. <br>
      20. Масъул ходимлар қабул қилган мурожаатнинг мазмунини дарҳол ўрганади ва мазмун бўйича маслаҳат бериш
      зарурияти бўлса, шу захоти мурожаатчига тезкор маслаҳат берилади. Шунга кўра, тегишли тартибда маслаҳат
      варағи расмийлаштирилади ва мурожаат назоратдан олинади.
      Маслаҳат варағи икки нусхада тузилади, бир нусхаси мурожаат муаллифига жавоб тариқасида берилади,
      иккинчи нусхаси Меҳнат-М марказида сақланади. <br>
      <h6>
        IV-боб. Мурожаатларни ҳал қилишда медиацияни қўллаш тартиби
      </h6>
      21. Якка меҳнат низоларига муносабатлардан келиб чиқадиган низоларга медиацияни қўллаш Ўзбекистон
      Республикасининг “Медиация тўғрисида”ги Қонунига мувофиқ амалга оширилади. <br>
      22. Меҳнат-М маркази меҳнат низоларини ўзаро келишув услубида ҳал этишда низо тарафлар учун тенг
      шароитларни таъминлайдиган алоҳида хонада ажратиш ва бошқа шароитларни яратиш билан боғлиқ ташкилий
      чораларни кўради. <br>
      23. Медиация тартиб-таомилини амалга ошириш натижалари бўйича тарафлар келиб чиққан низо ёхуд
      мажбуриятларни бажариш шартлари ва муддатлари хусусида ўзаро мақбул қарорга эришган тақдирда, тарафлар
      ўртасида ёзма шаклда медиатив келишув тузилади. Ушбу келишув нусҳаси электрон тизим (“Mehnat M” электрон
      портали)га киритилади ва шунга кўра мурожаат назоратдан олинади. <br>
      <h6>
        V-боб. Уч томонлама комиссия томонидан мурожаатларни кўриб чиқиш тартиби
      </h6>
      24. Меҳнат низоларини музокаралар асосида ҳал қилиш имкони бўлмаганда юзага келган низоли ҳолатлар
      ҳудудий ижтимоий-меҳнат масалалари бўйича уч томонлама комиссия кўмагида кўриб чиқилиши мумкин. <br>
      25. Ижтимоий-меҳнат масалалари бўйича уч томонлама комиссиянинг фаолияти Ўзбекистон Республикаси Меҳнат
      кодекси ҳамда Ўзбекистон Республикаси Вазирлар Маҳкамасининг 2019 йил 3 июлдаги
      553-сон қарори билан тасдиқланган “Ижтимоий-меҳнат масалалари бўйича уч томонлама комиссиялар
      тўғрисида”ги Низом талабларига мувофиқ ташкил этилади.
      <br>
      <h6>
        VI-боб. Меҳнат-М марказининг мурожаатлар, аризалар ва шикоятларни кўриб чиқишда мажбуриятлари ва
      </h6>
      ҳуқуқлари
      26. Меҳнат-М маркази мурожаатларни кўриб чиқишда қуйидагиларга мажбур:
      ҳар бир мурожаатни Ўзбекистон Республикасининг “Жисмоний ва юридик шахсларнинг мурожаатлари тўғрисида”ги
      Қонуни талабларига мувофиқ кўриб чиқиш; <br>
      Ўзбекистон Республикаси Бандлик ва меҳнат муносабатлари вазирлиги ҳамда Давлат меҳнат инспекцияси
      томонидан юборилган жисмоний ва юридик шахсларнинг мурожаатларини жисмоний ва юридик шахсларнинг меҳнат
      муносабатлари соҳасидаги мурожаатларини қабул қилиш, меҳнат ва меҳнатни муҳофаза қилиш, меҳнат ҳуқуқлари
      тўғрисидаги ахборотларни тақдим этиш бўйича “Mehnat M” электрон портали (кейинги ўринларда “портал” деб
      юритилади)да рўйхатга олади ва ижрога қаратади;
      жисмоний ва юридик шахсларнинг меҳнат соҳасидаги ҳуқуқлари бузилган тақдирда, ушбу ҳуқуқларни тиклаш
      борасида кўмаклашади;
      меҳнат муносабатлари субъектлари, шу жумладан касаначилик шартлари асосида меҳнат фаолиятини амалга
      оширувчи ходимлар ҳамда шахсий томорқа, деҳқон ва фермер хўжаликларида банд бўлган шахсларнинг меҳнатни
      муҳофаза қилиш ва меҳнат ҳуқуқларини тиклашга оид ишларни ташкил этади;
      ҳамкорликда иш олиб бораётган ташкилот вакиллари томонидан меҳнат соҳасидаги қонунчилик талаблари
      бузилиш ҳолатлари аниқланганда айбдор иш берувчиларга нисбатан қонунда белгиланган чоралар кўриш учун
      тўпланган ҳужжатларни Давлат меҳнат инспекциясига юборади. <br>
      27. Меҳнат-М маркази мурожаатларни кўриб чиқишда қуйидаги ҳуқуқларга эга:
      мурожаатни кўриб чиқиш учун зарур бўлган ахборотни белгиланган тартибда сўраш ва олиш;
      мурожаат қилувчи ёки бошқа шахс йўқлигида мурожаатни кўриб чиқиш мумкин бўлмаганда ёхуд мурожаатни
      эшитиш учун уни чақириш;
      била туриб ёлғон маълумотлар кўрсатилган мурожаатни текшириш сабабли етказилган харажатларнинг ўрнини
      қоплаш тўғрисида судга мурожаат қилиш.
      Меҳнат-М маркази қонунчиликка мувофиқ бошқа ҳуқуқларга ҳам эга бўлиши мумкин. <br>
      28. Меҳнат-М маркази:
      мурожаатлар тўғрисидаги қонунчилик талабларига риоя этиши;
      мурожаат қилувчига унинг ҳуқуқлари, эркинликлари ва қонуний манфаатларига дахлдор ҳужжатлар, қарорлар ва
      бошқа материаллар билан танишиб чиқиш имкониятини, агар улар давлат сири ёки қонун билан қўриқланадиган
      бошқа сир бўлган маълумотларни ўз ичига олмаса, жисмоний ва юридик шахсларнинг ҳуқуқлари, эркинликлари
      ва қонуний манфаатларига, жамият ва давлат манфаатларига зарар етказмаса, таъминлаши;
      мурожаат этувчига кўриб чиқиш натижалари ҳамда қабул қилинган қарор ҳақида мурожаат кўриб чиқилганидан
      сўнг ёзма ёхуд электрон шаклда дарҳол хабар қилиши, оммавий қабуллар вақтида тушган ва жойида ҳал
      қилинган оғзаки мурожаатлар бундан мустасно;
      мурожаат юзасидан қабул қилинган қарор устидан, агар жисмоний ёки юридик шахс ушбу қарорга рози бўлмаса,
      шикоят бериш тартибини тушунтириши;
      мурожаатни кўриб чиқиш натижаларига кўра қабул қилинган қарорнинг ижросини назорат қилиши;
      қонунга хилоф ҳаракатларни (ҳаракатсизликни) бартараф этиш юзасидан дарҳол чора-тадбирлар кўриши,
      жисмоний ва юридик шахсларнинг ҳуқуқлари, эркинликлари ва қонуний манфаатлари бузилишини келтириб
      чиқарувчи сабаблар ва шароитларни ўз ваколатлари доирасида аниқлаши шарт.
      <br>
      <h6>
        VII-боб. Меҳнат-М марказининг ҳисоботи
      </h6>

      29. Меҳнат-М маркази Ўзбекистон Республикаси қонун ҳужжатлари асосида ўз фаолияти натижаларининг
      ҳисобини амалга оширади, статистик ҳисобот олиб боради ва ҳисоботни ишончлилигини таъминлайди.
      30. Барча ҳисоботларни Меҳнат-М маркази ҳисобот ойи якуни билан кейинги ойининг 5-санасига қадар
      Ўзбекистон Республикаси Бандлик ва меҳнат муносабатлари вазирлигининг Давлат меҳнат инспекциясига
      белгиланган тартибда тақдим қилади. <br>
      <h6>
        VIII-боб. Якунловчи қоидалар.
      </h6>
      31. Меҳнат-М маркази ходимлари ўз хизмат вазифаларини бажармаганлиги ёки етарли даражада бажармаганлиги,
      Вазирлик тизимининг коррупцияга қарши курашиш сиёсати, меҳнат органлари ходимларининг одоб-ахлоқ
      қоидаларини бузганликлари, шунингдек, хуқуққа хилоф харакат ёки харакатсизлик, хизмат мажбуриятларини
      бажаришда юклатилган ваколатлардан бош тортганлик учун қонун ҳужжатларида белгиланган тартибда интизомий
      ёки бошқа жавобгарликка тортиладилар.
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
